import { createSlice } from '@reduxjs/toolkit';
import {
  getRewards,
  getHistoryTransactions,
  loadMoreHistoryTransaction,
} from './thunks';

export const { actions, reducer } = createSlice({
  name: 'membership',
  initialState: {
    statusApiRewards: 'idle',
    statusApiTransactions: 'idle',
    statusApiTransactionsLoadMore: 'idle',
    rewards: [],
    transactionsHistory: [],
    transactionsActive: [],
  },
  reducers: {
    setRewards(state, { data }) {
      state.rewards = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRewards.pending, (state) => {
        state.statusApiRewards = 'pending';
      })
      .addCase(getRewards.rejected, (state) => {
        state.statusApiRewards = 'failed';
      })
      .addCase(getRewards.fulfilled, (state, { payload }) => {
        state.rewards = payload.data;
        state.statusApiRewards = 'succeeded';
      })
      .addCase(getHistoryTransactions.pending, (state) => {
        state.statusApiTransactions = 'pending';
      })
      .addCase(getHistoryTransactions.rejected, (state) => {
        state.statusApiTransactions = 'failed';
      })
      .addCase(getHistoryTransactions.fulfilled, (state, { payload }) => {
        if (payload.type === 'history') {
          state.transactionsHistory = payload.data;
        }

        if (payload.type === 'active') {
          state.transactionsActive = payload.data;
        }
        state.statusApiTransactions = 'succeeded';
      })
      .addCase(loadMoreHistoryTransaction.rejected, (state) => {
        state.statusApiTransactionsLoadMore = 'failed';
      })
      .addCase(loadMoreHistoryTransaction.pending, (state) => {
        state.statusApiTransactionsLoadMore = 'pending';
      })
      .addCase(loadMoreHistoryTransaction.fulfilled, (state, { payload }) => {
        state.statusApiTransactionsLoadMore = 'succeeded';
        if (payload.type === 'history') {
          state.transactionsHistory = [
            ...state.transactionsHistory,
            ...payload.data,
          ];
        }

        if (payload.type === 'active') {
          state.transactionsActive = [
            ...state.transactionsActive,
            ...payload.data,
          ];
        }
      });
  },
});

export default reducer;
