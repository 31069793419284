import { createSlice } from '@reduxjs/toolkit';
import {
  getRecentlyBought,
  getUserReviews,
  getUserReviewsCount,
} from '~store/my-reviews/thunks';

export const { reducer } = createSlice({
  name: 'myReviews',
  initialState: {
    recentlyBought: '',
    userReviews: '',
    userReviewsCount: 0,
    status: {
      recentlyBought: 'idle',
      userReviews: 'idle',
      userReviewsCount: 'idle',
    },
  },
  reducers: {
    setState(state, { name, value }) {
      state[name] = value;
    },
    setStatusApi(state, { name, value }) {
      state.status[name] = value;
    },
    resetState(state) {
      state.recentlyBought = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecentlyBought.rejected, (state) => {
        state.status.recentlyBought = 'failed';
      })
      .addCase(getRecentlyBought.pending, (state) => {
        state.status.recentlyBought = 'loading';
      })
      .addCase(getRecentlyBought.fulfilled, (state, { payload }) => {
        state.recentlyBought = payload.data;
        state.status.recentlyBought = 'succeeded';
      })
      .addCase(getUserReviewsCount.pending, (state) => {
        state.status.userReviewsCount = 'loading';
      })
      .addCase(getUserReviewsCount.fulfilled, (state, { payload }) => {
        state.userReviewsCount = payload;
        state.status.userReviewsCount = 'succeeded';
      })
      .addCase(getUserReviews.rejected, (state) => {
        state.status.userReviews = 'failed';
      })
      .addCase(getUserReviews.pending, (state) => {
        state.status.userReviews = 'loading';
      })
      .addCase(getUserReviews.fulfilled, (state, { payload }) => {
        state.userReviews = payload.data;
        state.status.userReviews = 'succeeded';
      });
  },
});

export default reducer;
