import { createSlice } from '@reduxjs/toolkit';
import {
  countries,
  provinces,
  cities,
  districts,
} from '~store/location/thunks';

export const { actions, reducer } = createSlice({
  name: 'location',
  initialState: {
    countries: [],
    provinces: [],
    cities: [],
    districts: [],
  },
  reducers: {
    resetLocation: (state, action) => {
      state[action.payload] = [];
    },
  },
  extraReducers: {
    [countries.fulfilled]: (state, { payload }) => {
      state.countries = payload;
    },
    [provinces.fulfilled]: (state, { payload }) => {
      state.provinces = payload;
    },
    [cities.fulfilled]: (state, { payload }) => {
      state.cities = payload;
    },
    [districts.fulfilled]: (state, { payload }) => {
      state.districts = payload;
    },
  },
});

export const { resetLocation } = actions;
export default reducer;
