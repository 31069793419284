import { createSlice } from '@reduxjs/toolkit';
import {
  addNewMomChecklist,
  addNewMomChecklistItem,
  deleteMomChecklist,
  deleteMomChecklistItem,
  getMomChecklist,
  getProductsMomChecklist,
  getTotalProductsMomChecklist,
  isHiddenChecklist,
  loadMoreProductsMomChecklist,
  updateMomChecklistItem,
  updateNewMomChecklist,
  addProductRecommendationsMomChecklist,
  updateProductRecommendationsMomChecklist,
  searchProductMomChecklist,
  searchProductMomChecklistCount,
  getLoadMoreListMomChecklist,
  getMomChecklistTotal,
  updateProductRecomendationChecked,
} from '~store/mom-checklist/thunks';

export const { actions, reducer } = createSlice({
  name: 'momChecklist',
  initialState: {
    data: {},
    totalData: 0,
    listProducts: [],
    totalProducts: '...',
    searchProductList: [],
    searchProductListCount: '...',
    reffModal: null,
    tabMenu: [
      {
        id: 0,
        name: 'New Moms',
        is_active: false,
      },
      {
        id: 1,
        name: 'Lil One',
        is_active: false,
      },
    ],
    status: {
      data: 'idle',
      addNewChecklist: 'idle',
      deleteChecklist: 'idle',
      deleteMomChecklistItem: 'idle',
      listProducts: 'idle',
      totalProducts: 'idle',
      loadMoreProducts: 'idle',
      addNewChecklistItem: 'idle',
      updateNewChecklist: 'idle',
      updateChecklistItem: 'idle',
      addProductRecommendationsMomChecklist: 'idle',
      updateProductRecommendationsMomChecklist: 'idle',
      searchProductList: 'idle',
      searchProductListCount: 'idle',
      getLoadMoreListMomChecklist: 'idle',
      getMomChecklistTotal: 'idle',
      updateProductRecomendationChecked: 'idle',
    },
  },
  reducers: {
    setMomChecklist: (state, { data }) => {
      state.data = data;
    },
    setMomChecklistProducts: (state, { data }) => {
      state.listProducts = data;
    },
    setMomChecklistTotalProducts: (state, { data }) => {
      state.totalProducts = data;
    },
    setStatusApi(state, { value, name }) {
      state.status[name] = value;
    },
    setTabMenu(state, { data }) {
      state.tabMenu.forEach((tab) => {
        tab.is_active = false;
      });
      state.tabMenu[data].is_active = true;
    },
    setReffModal(state, { data }) {
      state.reffModal = data;
    },
    setSearchProductList: (state, { data }) => {
      state.searchProductList = data;
    },
    setStatusSearchProducts: (state, { data }) => {
      state.status.searchProductList = data;
    },
    setMoreHidden(state, { data }) {
      const index = state.data.findIndex((item) => item.idx === data.id);
      if (index !== -1) {
        state.data[index] = {
          ...state.data[index],
          ...data,
          is_more_hidden: data.is_more_hidden,
        };
      }
    },
    resetMomChecklistState(state) {
      state.data = {};
      state.listProducts = [];
      state.totalProducts = '...';
      state.searchProductList = [];
      state.searchProductListCount = '...';
      state.reffModal = null;
      state.tabMenu = [
        {
          id: 0,
          name: 'New Moms',
          is_active: false,
        },
        {
          id: 1,
          name: 'Lil One',
          is_active: false,
        },
      ];
      state.status = {
        data: 'idle',
        addNewChecklist: 'idle',
        deleteChecklist: 'idle',
        deleteMomChecklistItem: 'idle',
        listProducts: 'idle',
        totalProducts: 'idle',
        loadMoreProducts: 'idle',
        isHiddenChecklist: 'idle',
        updateNewChecklist: 'idle',
        updateChecklistItem: 'idle',
        addProductRecommendationsMomChecklist: 'idle',
        updateProductRecommendationsMomChecklist: 'idle',
        searchProductList: 'idle',
        searchProductListCount: 'idle',
        getMomChecklistTotal: 'idle',
        updateProductRecomendationChecked: 'idle',
      };
    },
    setCheckedProduct(state, { data }) {
      try {
        const { idx, subIdx, productIndex, newValue } = data;
        state.data[idx].items[subIdx].products[productIndex].is_checked =
          newValue;
      } catch (error) {
        console.log(error);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMomChecklist.pending, (state) => {
        state.status.data = 'pending';
      })
      .addCase(getMomChecklist.fulfilled, (state, { payload }) => {
        state.status.data = 'succeeded';
        state.data = payload.data.map((item, idx) => ({
          ...item,
          idx: idx,
          is_expand: false,
          is_more: false,
          is_more_hidden: false,
          items: item.items.map((subItem, subIdx) => ({
            ...subItem,
            subIdx: subIdx,
            is_sub_expand: false,
            is_editable: false,
            is_default: item.is_default,
            products: subItem?.products?.map((product, productIndex) => ({
              ...product,
              subIdx,
              idx,
              productIndex,
            })),
          })),
        }));
      })
      .addCase(addNewMomChecklist.pending, (state) => {
        state.status.addNewChecklist = 'pending';
      })
      .addCase(addNewMomChecklist.fulfilled, (state, { payload }) => {
        state.status.addNewChecklist = 'succeeded';
        payload.data.idx = state.data.length - 1;
        payload.data.is_expand = false;
        payload.data.is_more = false;
        payload.data.is_more_hidden = false;
        for (let i = 0; i < payload.data.items.length; i++) {
          payload.data.items[i].subIdx = i;
          payload.data.items[i].is_sub_expand = false;
          payload.data.items[i].is_editable = false;
        }
        state.data.push(payload.data);
      })
      .addCase(getMomChecklistTotal.pending, (state) => {
        state.status.getMomChecklistTotal = 'pending';
      })
      .addCase(getMomChecklistTotal.fulfilled, (state, { payload }) => {
        state.status.getMomChecklistTotal = 'succeeded';
        state.totalData = payload.data;
      })
      .addCase(deleteMomChecklist.pending, (state) => {
        state.status.deleteChecklist = 'pending';
      })
      .addCase(deleteMomChecklist.fulfilled, (state, { payload }) => {
        state.status.deleteChecklist = 'succeeded';
        state.data = state.data.filter((item) => item._id !== payload.data._id);
      })
      .addCase(deleteMomChecklistItem.pending, (state) => {
        state.status.deleteMomChecklistItem = 'pending';
      })
      .addCase(deleteMomChecklistItem.fulfilled, (state, { payload }) => {
        state.status.deleteMomChecklistItem = 'succeeded';
        state.data = state.data.filter((item) => item._id !== payload.data._id);
      })
      .addCase(getProductsMomChecklist.pending, (state) => {
        state.status.listProducts = 'pending';
      })
      .addCase(getProductsMomChecklist.fulfilled, (state, { payload }) => {
        state.status.listProducts = 'succeeded';
        state.listProducts = payload.data;
      })
      .addCase(getTotalProductsMomChecklist.pending, (state) => {
        state.status.totalProducts = 'pending';
      })
      .addCase(getTotalProductsMomChecklist.fulfilled, (state, { payload }) => {
        state.status.totalProducts = 'succeeded';
        state.totalProducts = payload.data;
      })
      .addCase(loadMoreProductsMomChecklist.pending, (state) => {
        state.status.loadMoreProducts = 'pending';
      })
      .addCase(loadMoreProductsMomChecklist.fulfilled, (state, { payload }) => {
        state.status.loadMoreProducts = 'succeeded';
        state.listProducts = [...state.listProducts, ...payload.data];
      })
      .addCase(isHiddenChecklist.pending, (state) => {
        state.status.isHiddenChecklist = 'pending';
      })
      .addCase(isHiddenChecklist.fulfilled, (state, { payload }) => {
        state.status.isHiddenChecklist = 'succeeded';
        state.data = state.data.map((item) => {
          if (item._id === payload.data._id) {
            return payload.data;
          }
          return item;
        });
      })
      .addCase(addNewMomChecklistItem.pending, (state) => {
        state.status.addNewChecklistItem = 'pending';
      })
      .addCase(addNewMomChecklistItem.fulfilled, (state, { payload }) => {
        state.status.addNewChecklistItem = 'succeeded';
        state.data = state.data.map((item) => {
          if (item._id === payload.data._id) {
            return { ...item, items: [...item.items, payload.data] };
          }
          return item;
        });
      })
      .addCase(updateNewMomChecklist.pending, (state) => {
        state.status.updateNewChecklist = 'pending';
      })
      .addCase(updateNewMomChecklist.fulfilled, (state, { payload }) => {
        state.status.updateNewChecklist = 'succeeded';
        state.data = state.data.map((item) => {
          if (item._id === payload.data._id) {
            return payload.data;
          }
          return item;
        });
      })
      .addCase(updateMomChecklistItem.pending, (state) => {
        state.status.updateChecklistItem = 'pending';
      })
      .addCase(updateMomChecklistItem.fulfilled, (state, { payload }) => {
        state.status.updateChecklistItem = 'succeeded';
        state.data = state.data.map((item) => {
          if (item._id === payload.data._id) {
            return payload.data;
          }
          return item;
        });
      })
      .addCase(addProductRecommendationsMomChecklist.pending, (state) => {
        state.status.addProductRecommendationsMomChecklist = 'pending';
      })
      .addCase(
        addProductRecommendationsMomChecklist.fulfilled,
        (state, { payload }) => {
          state.status.addProductRecommendationsMomChecklist = 'succeeded';
          state.data.items = [...state.data.items, payload.data];
        },
      )
      .addCase(updateProductRecommendationsMomChecklist.pending, (state) => {
        state.status.updateProductRecommendationsMomChecklist = 'pending';
      })
      .addCase(
        updateProductRecommendationsMomChecklist.fulfilled,
        (state, { payload }) => {
          state.status.updateProductRecommendationsMomChecklist = 'succeeded';
          state.data = state.data.map((item) => {
            if (item._id === payload.data._id) {
              return payload.data;
            }
            return item;
          });
        },
      )
      .addCase(searchProductMomChecklist.pending, (state) => {
        state.status.searchProductList = 'pending';
      })
      .addCase(searchProductMomChecklist.fulfilled, (state, { payload }) => {
        state.status.searchProductList = 'succeeded';
        state.searchProductList = payload.data;
      })
      .addCase(searchProductMomChecklistCount.pending, (state) => {
        state.status.searchProductListCount = 'pending';
      })
      .addCase(
        searchProductMomChecklistCount.fulfilled,
        (state, { payload }) => {
          state.status.searchProductListCount = 'succeeded';
          state.searchProductListCount = payload.data;
        },
      )
      .addCase(getLoadMoreListMomChecklist.pending, (state) => {
        state.status.getLoadMoreListMomChecklist = 'pending';
      })
      .addCase(getLoadMoreListMomChecklist.fulfilled, (state, { payload }) => {
        state.status.getLoadMoreListMomChecklist = 'succeeded';
        const newData = payload.data.map((item, idx) => ({
          ...item,
          idx: state.data.length + idx,
          is_expand: false,
          is_more: false,
          is_more_hidden: false,
          items: item.items.map((subItem, subIdx) => ({
            ...subItem,
            subIdx: subIdx,
            is_sub_expand: false,
            is_editable: false,
            products: subItem?.products?.map((product, productIndex) => ({
              ...product,
              subIdx,
              idx: state.data.length + idx,
              productIndex,
            })),
          })),
        }));
        state.data = [...state.data, ...newData];
      })
      .addCase(updateProductRecomendationChecked.pending, (state) => {
        state.status.updateProductRecomendationChecked = 'pending';
      })
      .addCase(updateProductRecomendationChecked.fulfilled, (state) => {
        state.status.updateProductRecomendationChecked = 'succeeded';
      });
  },
});

export default reducer;
