import { createAsyncThunk } from '@reduxjs/toolkit';
import { Soco } from '~api/soco';

export const getRewards = createAsyncThunk(
  'membership/getRewards',
  async (params) => {
    const response = await Soco.getRewards(params);

    return response.data;
  },
);

export const getUserReward = createAsyncThunk(
  'membership/getUserReward',
  async (userId) => {
    const response = await Soco.getUserReward(userId);
    return response.data;
  },
);

export const getHistoryTransactions = createAsyncThunk(
  'membership/getHistoryTransactions',
  async (params) => {
    const type = params.type; // this use only for define in slice, dont pass to params
    delete params.type;
    const response = await Soco.getHistoryTransactions(params);
    response.data.type = type;
    return response.data;
  },
);

export const loadMoreHistoryTransaction = createAsyncThunk(
  'membership/loadMoreHistoryTransaction',
  async (params) => {
    const type = params.type; // this use only for define in slice, dont pass to params
    delete params.type;
    const response = await Soco.getHistoryTransactions(params);
    response.data.type = type;
    return response.data;
  },
);
