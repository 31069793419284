import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '~api/location';

export const countries = createAsyncThunk(
  'location/countries',
  async (params) => await api.getCountries(params),
);
export const provinces = createAsyncThunk(
  'location/provinces',
  async () => await api.getProvinces(),
);
export const cities = createAsyncThunk(
  'location/cities',
  async (params) => await api.getCities(params),
);
export const districts = createAsyncThunk(
  'location/districts',
  async (params) => await api.getDistricts(params),
);
