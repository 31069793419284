import { createAsyncThunk } from '@reduxjs/toolkit';
import { Orders } from '~api/orders';
import { Soco } from '~api/soco';

export const getRecentlyBought = createAsyncThunk(
  'myReviews/getRecentlyBought',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Orders.getRecentlyBought(payload);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);

export const getUserReviewsCount = createAsyncThunk(
  'myReviews/getUserReviewsCount',
  async (payload) => {
    try {
      return await Soco.getUserReviewsCount(payload).then(
        ({ data: { data } }) => data,
      );
    } catch (err) {
      console.log(err);
    }
  },
);

export const getUserReviews = createAsyncThunk(
  'myReviews/getUserReviews',
  async ({ params, userId }, { rejectWithValue }) => {
    try {
      const response = await Soco.getUserReviews({ params, userId });
      response.data.data.map((newsfeed, indexNewsfeed) => {
        // eslint-disable-next-line no-prototype-builtins
        if (newsfeed.hasOwnProperty('review')) {
          Object.entries(newsfeed.review).map(([propertyName, value]) => {
            // eslint-disable-next-line no-prototype-builtins
            if (!newsfeed.hasOwnProperty(propertyName)) {
              response.data.data[indexNewsfeed][propertyName] = value;
            }
          });
        }
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);
