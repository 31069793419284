import { createAsyncThunk } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';
import lulla from '~api/lulla';

export const getMomChecklist = createAsyncThunk(
  'momChecklist/getMomChecklist',
  async (params) => {
    const response = await lulla.getMomChecklist(params);
    return response.data;
  },
);

export const getMomChecklistTotal = createAsyncThunk(
  'momChecklist/getMomChecklistTotal',
  async (params) => {
    const response = await lulla.getMomChecklistTotal(params);
    return response.data;
  },
);

export const addNewMomChecklist = createAsyncThunk(
  'momChecklist/addNewMomChecklist',
  async (payload) => {
    const response = await lulla.addMomChecklistNew(payload);
    return response.data;
  },
);

export const deleteMomChecklist = createAsyncThunk(
  'momChecklist/deleteMomChecklist',
  async (id) => {
    const response = await lulla.deleteMomChecklist(id);
    return response.data;
  },
);

export const deleteMomChecklistItem = createAsyncThunk(
  'momChecklist/deleteMomChecklistItem',
  async (params) => {
    const response = await lulla.deleteMomChecklistItem(params);
    return response.data;
  },
);

export const getProductsMomChecklist = createAsyncThunk(
  'momChecklist/getProductsMomChecklist',
  async (params) => {
    const response = await Catalog.getProductsRequest(params);
    return response.data;
  },
);

export const getTotalProductsMomChecklist = createAsyncThunk(
  'momChecklist/getTotalProductsMomChecklist',
  async (params) => {
    const response = await Catalog.getTotalProductsRequest(params);
    return response.data;
  },
);

export const loadMoreProductsMomChecklist = createAsyncThunk(
  'momChecklist/loadMoreProductsMomChecklist',
  async (params) => {
    const response = await Catalog.getProductsRequest(params);
    return response.data;
  },
);

export const isHiddenChecklist = createAsyncThunk(
  'momChecklist/isHiddenChecklist',
  async (params) => {
    const response = await lulla.updateHiddenMomChecklist(params);
    return response.data;
  },
);

export const addNewMomChecklistItem = createAsyncThunk(
  'momChecklist/addNewMomChecklistItem',
  async (payload) => {
    const response = await lulla.addMomChecklistItem(payload);
    return response.data;
  },
);
export const updateMomChecklistItem = createAsyncThunk(
  'momChecklist/updateMomChecklistItem',
  async (payload) => {
    const response = await lulla.updateMomChecklistItem(payload);
    return response.data;
  },
);

export const updateNewMomChecklist = createAsyncThunk(
  'momChecklist/updateMomChecklist',
  async (payload) => {
    const response = await lulla.updateMomChecklistNew(payload);
    return response.data;
  },
);

export const addProductRecommendationsMomChecklist = createAsyncThunk(
  'momChecklist/addProductRecommendationsMomChecklist',
  async (payload) => {
    const response = await lulla.addProductRecommendationsMomChecklist(payload);
    return response.data;
  },
);

export const updateProductRecommendationsMomChecklist = createAsyncThunk(
  'momChecklist/updateProductRecommendationsMomChecklist',
  async (payload) => {
    const response = await lulla.updateProductRecommendationsMomChecklist(
      payload,
    );
    return response.data;
  },
);

export const searchProductMomChecklist = createAsyncThunk(
  'momChecklist/searchProductMomChecklist',
  async (params) => {
    const response = await Catalog.searchV3Products(params);
    return response.data;
  },
);

export const searchProductMomChecklistCount = createAsyncThunk(
  'momChecklist/searchProductMomChecklistCount',
  async (params) => {
    const response = await Catalog.searchTotalProductsRequest(params);
    return response.data;
  },
);

export const getLoadMoreListMomChecklist = createAsyncThunk(
  'momChecklist/getLoadMoreListMomChecklist',
  async (params) => {
    const response = await lulla.getMomChecklist(params);
    return response.data;
  },
);

export const updateProductRecomendationChecked = createAsyncThunk(
  'momChecklist/updateProductRecomendationChecked',
  async (params) => {
    const response = await lulla.updateProductRecomendationChecked(params);
    return response.data;
  },
);
